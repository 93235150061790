import React, { useEffect } from 'react';
import Script from 'next/script'
import clientRendering from '../../../common/clientRendering';
import { useRouter } from 'next/router';

function YandexMetrika(props) {
  if (!clientRendering) {
    return null;
  }

  console.log('Init YandexMetrika');
  // console.log('process.env.YANDEX_METRIKA_ID', process.env.YANDEX_METRIKA_ID);

  const router = useRouter();
  const pathname = router.pathname;
  
  useEffect(() => {
    const url = `${pathname}`; // ?${searchParams}
    
    if (ym) {
      ym(process.env.YANDEX_METRIKA_ID, 'hit', url);
    } else {
      console.log('YANDEX METRIKA HIT NOT SENT because "ym" undefined');
    }
  }, [router])

  return (
    <Script id="metrika-counter" strategy="afterInteractive">
      {`(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
        m[i].l=1*new Date();
        for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
        k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
        (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

        ym(${process.env.YANDEX_METRIKA_ID}, "init", {
          defer: true,
          clickmap:true,
          trackLinks:true,
          accurateTrackBounce:true,
          webvisor:true
        });`
      }
    </Script>
  );
}

export default YandexMetrika;
